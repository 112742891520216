<div class="swiper-box" wmAnimate="fadeIn" #swiperAnimationIn aos once speed="faster" [class.dark]="theme == 'dark'" [class.light]="theme == 'light'">
    <div class="swiper-title">
        {{ title }}
    </div>
    <div class="section-text">
        {{ description }}
    </div>
    <swiper>
        <div class="swiper-slide small-swiper-slide" *ngFor="let item of listadoItems" wmAnimate="pulse" speed="superFaster" aos once>
            <div class="slide-image">
                <img src="{{ item.image }}" alt="">
            </div>
            <div class="small-slide-title" *ngIf="item.title != ''">
                {{ item.title }}
            </div>
            <div class=" small-slide-description " [innerHTML]="item.description">>
                <!-- {{ item.description }} -->
            </div>
        </div>
    </swiper>
    <!-- <div class="swiper-custom-prev swiper-custom-arrow "></div>
    <div class="swiper-custom-next swiper-custom-arrow "></div> -->
    <div class="clearfix "></div>
    <div class="swiper-custom-pagination "></div>
</div>