<div class="container-body gracias-page">
    <app-main-slide [smallHeaderAlways]="true" [pais]=true></app-main-slide>
    <div id="second-section" class="main-content">
        <div class="section-wrapper">
            <div class="section-text gracias-description">
                <p>
                    Gracias por el interés en Vuelan Recibidos. En breve nos pondremos en contacto contigo para charlar de mas detalles.
                </p>
            </div>
        </div>
    </div>
</div>
<app-contact [gracias]=true></app-contact>