import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class GAService {
  constructor(
    private googleService: GoogleAnalyticsService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  pageView(page: string, title: string) {
    console.log('pageView');
    if (isPlatformBrowser(this.platformId)) {
      this.googleService.pageView(page, title);
    }
  }

  eventGA(category: string, action: string, label: string) {
    console.log('eventGA');
    if (isPlatformBrowser(this.platformId)) {
      this.googleService.event(category, action, label);
    }
  }
}
