<div id="queressabermas"></div>

<div id="contacto" class="footer">
    <div class="footer-first" *ngIf="!sended">


        <!-- <div id="el-viaje" class="section-wrapper">
          <div class="content-section"> -->
        <div class="foto-reunion " wmAnimate="fadeIn" speed="faster" aos once> <!-- banner-desktop -->
            <img class="foto-reunion" src="/assets/imgs/ReuDes.png" alt="">
        </div>
        <!-- <div class="foto-reunion banner-mobile" wmAnimate="fadeIn" speed="faster" aos once>
              <img src="/assets/imgs/ReuChelu.png" alt="">
            </div> -->
        <!-- </div>
        </div> -->

        <!-- <div class="footer-text">
          Primera reunión informativa 12/08 nos reunimos para hablar del viaje. <br> <br> Estamos abiertos de lunes a viernes de 10 a 18 hs en 21 de Setiembre 2323 esquina Gonzalo Ramirez.
        </div> -->

        <div class="footer-first" *ngIf="initForm">
            <div class="footer-title2">
                ¿Querés saber más?
            </div>

            <div class="contact-form">
                <div *ngIf="!sending && !sended">
                    <div class="form-input">
                        <input [(ngModel)]="name" type="text" placeholder="Nombre">
                    </div>
                    <div class="form-input">
                        <input [(ngModel)]="phone" type="text" placeholder="Teléfono">
                    </div>
                    <div class="form-input">
                        <input [(ngModel)]="mail" type="text" placeholder="Mail">
                    </div>
                    <!-- <div class="recaptcha-container">
                        <recaptcha [(ngModel)]="myRecaptcha" (scriptLoad)="onScriptLoad()" (scriptError)="onScriptError()"></recaptcha>
                    </div> -->
                    <div class="contact-error" *ngIf="formError">Debes completar todos los campos!</div>
                    <div class="clearfix"></div>
                    <div class="form-btn">
                        <div class="contact-btn" (click)="enviar()">ENVIAR</div>
                    </div>
                </div>
                <div class="loader-container" *ngIf="sending">
                    <img src="/assets/imgs/loader.gif" alt="">
                </div>
            </div>
        </div>
        <!-- <div class="footer-days">

        </div> -->
    </div>
    <div class="sended-msg" *ngIf="sended">
        Gracias por comunicarte con nosotros, en breve te contactamos para coordinar...<br><br>Saludos dTyT!
    </div>
    <div class="footer-second">
        <div class="footer-logo">
            <img src="/assets/imgs/logo-black.svg" alt="">
        </div>
        <div class="footer-contacts">
            <div class="contact-item">
                <div class="contact-img">
                    <img src="/assets/imgs/phone-icon.svg" alt="">
                </div>
                <div class="contact-text">
                    <a href="https://wa.me/59892443898" target="_blank">
                        2418 3898 / 092 443 898
                    </a>
                </div>
            </div>
            <div class="contact-item">
                <div class="contact-img">
                    <img src="/assets/imgs/mail-icon.svg" alt="">
                </div>
                <div class="contact-text">
                    <a href="mailto:universidades@detoqueytoque.com">
                        universidades@detoqueytoque.com
                    </a>
                </div>
            </div>
            <div class="contact-item">
                <div class="contact-img">
                    <img src="/assets/imgs/ig-icon.svg" alt="">
                </div>
                <div class="contact-text">
                    <a href="https://www.instagram.com/detoqueytoque" target="_blank">
                        @detoqueytoque
                    </a>
                </div>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
</div>