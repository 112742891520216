import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { IncluyeItem, PaisItem, StandardItem, TramoItem, PremioItem, AccordionItem, ChartItem, SlideItem, OpinionItem } from '../../interfaces/interfaces';
import { GAService } from 'src/app/services/gaservice.service';
import { isPlatformBrowser } from '@angular/common';

// let window:any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {


  public incluyeHomeList: IncluyeItem[] = [
    {
      icon: 'bag',
      title: 'Salida',
      subtitle: 'Julio / Agosto 2020',
    },
    {
      icon: 'bag',
      title: 'Salida',
      subtitle: 'Julio / Agosto 2020',
    },
    {
      icon: 'bag',
      title: 'Salida',
      subtitle: 'Julio / Agosto 2020',
    },
    {
      icon: 'bag',
      title: 'Salida',
      subtitle: 'Julio / Agosto 2020',
    },
  ];

  public opinionesDeViajeros: OpinionItem[] = [
    {
      /* image: '/assets/imgs/incluye/incluye_5.jpg', */
      title: '',
      description:
        '"La verdad nada más que decirles gracias, por hacer de este viaje una experiencia sin dudas inolvidable. Les agradezco la calidez, la responsabilidad, el buen trato y la habilidad para sacarnos una sonrisa en cualquier momento. Más allá de una empresa, sin tremendo equipo humano. Nos volveremos a encontrar en futuros viajes ❤️"',
    },
    {
      /* image: '/assets/imgs/incluye/incluye_5.jpg', */
      title: '',
      description:
        '"Banco mil. Más allá de que es súper libre siempre estuvimos asesorados y re mil cuidados por la agencia"',
    },
    {
      /* image: '/assets/imgs/incluye/incluye_5.jpg', */
      title: '',
      description:
        '"Creo que está bueno que en cada lugar siempre ubieron propuestas y nosotros decidimos que hacemos o no. Y las actividades principales las hacíamos en grupo.Nos sentimos re cómodos así"',
    },
    {
      /* image: '/assets/imgs/incluye/incluye_5.jpg', */
      title: '',
      description:
        '"Fue todo lo que esperaba del viaje, siento que era necesario vivirlo de esa forma. Que estén integrando a todo el grupo me pareció un éxito, yo fui con un grupo grande de amigos pero me copaba conocer más gente y sucedió también gracias a la forma de encarar e integrar"',
    },
    {
      /* image: '/assets/imgs/incluye/incluye_5.jpg', */
      title: '',
      description:
        '"La libertad del viaje es uno los puntos altos. La app juega un rol fundamental para desenvolvernos en cada destino y las recomendaciones también son clave"',
    },
    {
      /* image: '/assets/imgs/incluye/incluye_5.jpg', */
      title: '',
      description:
        '"Son unos genios, la onda que le metieron al viaje hizo mejor la experiencia."',
    },

    {
      /* image: '/assets/imgs/incluye/incluye_5.jpg', */
      title: '',
      description:
        '"De las mejores experiencias y recuerdos para el resto de la vida 🙌🏻👏🏻. Hacerlo con amigos de facultad y además con dtyt que son todos muy genios, no hay forma de que salga mal."',
    },

    {
      /* image: '/assets/imgs/incluye/incluye_5.jpg', */
      title: '',
      description:
        '"Mi recomendación a próximos viajeros es que vayan con la cabeza abierta, entendiendo que no todos los lugares a los que van son lugares de joda o de playa, sino que vas a vivir tremenda experiencia, a conocer culturas totalmente distintas a lo que tenemos presente en el día a día."',
    },

    {
      /* image: '/assets/imgs/incluye/incluye_5.jpg', */
      title: '',
      description:
        '"Todavía no dimensiono todo lo que fue este viaje y la cantidad de cosas que me dejo. Gracias por todo! Aguante diseño locooo!"',
    },
    {
      /* image: '/assets/imgs/incluye/incluye_5.jpg', */
      title: '',
      description:
        '"Como me van a mandar este form a la mitad de mi jornada laboral noven que me destruyen?? GRACIAS por todo, un abrazo para Nico, Vicky, Guille y Lito. Ya quiero sumarme a otro viaje con dtyt, superó totalmente lo que me imaginaba y me sorprendió de la mejor manera."',
    },
    {
      /* image: '/assets/imgs/incluye/incluye_5.jpg', */
      title: '',
      description:
        '"Gracias por hacer una propuesta de viaje para estudiantes que sea financiable con rifas. Creo que de otra forma no hubiese podido conocer estos países, ya sea por el tema económico o por el choque cultural que implica cada uno de los destinos."',
    },
    {
      /* image: '/assets/imgs/incluye/incluye_5.jpg', */
      title: '',
      description:
        '"Mi recuerdo del viaje es tremendo, de puro goce con mis amigos y toda la gente bella que cruce. Mi recuerdo de uds, es con cariño, son linda gente, agradezco haberlos cruzado también. Ya se los dije, pero gracias por todos los momentos, la calma y buena onda que manejan es clave y se agradece ante el shock cultural que es Asia, toda una experiencia!"',
    },
  ];

  public incluyeGeneralItems: StandardItem[] = [
    {
      image: '/assets/imgs/incluye/incluye_5.jpg',
      title: '',
      description:
        'Vuelo Montevideo - Asia - Montevideo (Tasas e impuestos incluidos).',
    },
    {
      image: '/assets/imgs/incluye/incluye_6.jpg',
      title: '',
      description:
        'Hospedajes en <strong>hoteles</strong> con desayuno incluido.',
    },
    {
      image: '/assets/imgs/incluye/incluye_7.jpg',
      title: '',
      description:
        'Todos los transportes (Vuelos internos, buses, trenes, ferrys).',
    },
    {
      image: '/assets/imgs/incluye/incluye_222.jpg',
      title: '',
      description: 'App para el viajero de dTyT',
    },
    {
      image: '/assets/imgs/incluye/incluye_12.jpg',
      title: '',
      description: 'Gestión y costo de visas.',
    },
    // {
    //   image: '/assets/imgs/incluye/incluye_111.jpg',
    //   title: '',
    //   description: 'Seguro de viaje.',
    // },
    {
      image: '/assets/imgs/incluye/incluye_1.jpg',
      title: '',
      description:
        '1 persona de dTyT cada 30 viajeros, todos con experiencia en los destinos y en manejo de grupos, brindando soluciones, piques, ideas y estar ahí para lo que se necesite en todo momento.',
    },
    {
      image: '/assets/imgs/incluye/incluye_4.jpg',
      title: '',
      description: '1 médico por grupo que viaje.',
    },
    {
      image: '/assets/imgs/incluye/incluye_3.jpg',
      title: '',
      description:
        'Contactos locales de suma confianza en cada destino, de forma tal de tener una experiencia única y segura en cada lugar.',
    },
    {
      image: '/assets/imgs/incluye/incluye_333.jpg',
      title: '',
      description:
        'Respaldo y experiencia frente a cualquier situación que acontece en el mundo: Pandemia, accidentes naturales, etc. con capacidad de resolución y adaptación.',
    },
    // {
    //   image: '/assets/imgs/incluye/incluye_10.jpg',
    //   title: '',
    //   description:
    //     'Organización en conjunto con la Facultad de Medicina para la realización de 2 actividades académicas opcionales durante el viaje (ver “sobre el viaje oficial de la Facultad de Medicina).',
    // },
    // {
    //   image: '/assets/imgs/incluye/incluye_2.jpg',
    //   title: '',
    //   description:
    //     'Curso de inglés enfocado a viajes de 3 meses de duración (2 veces por semana). El objetivo del curso es la fluidez y conversación.',
    // },
  ];

  public paises: any[] = [
    {
      id: 1,
      image: '/assets/imgs/itinerario/itinerario_india.jpg',
      imageMobile: '/assets/imgs/itinerario/itinerario_mobile_india.jpg',
      name: 'India',
      subtitle: '8 días',
      button: true,
      link: 'india',
    },
    {
      id: 2,
      image: '/assets/imgs/itinerario/itinerario_nepal.jpg',
      imageMobile: '/assets/imgs/itinerario/itinerario_mobile_nepal.jpg',
      name: 'Nepal',
      subtitle: '8 días',
      button: true,
      link: 'nepal',
    },
    {
      id: 3,
      image: '/assets/imgs/itinerario/itinerario_tailandia.jpg',
      imageMobile: '/assets/imgs/itinerario/itinerario_mobile_tailandia.jpg',
      name: 'Tailandia',
      subtitle: '10 días',
      button: true,
      link: 'tailandia',
    },
    {
      id: 4,
      image: '/assets/imgs/itinerario/itinerario_camboya.jpg',
      imageMobile: '/assets/imgs/itinerario/itinerario_mobile_camboya.jpg',
      name: 'Camboya',
      subtitle: '4 días',
      button: true,
      link: 'camboya',
    },
    {
      id: 5,
      image: '/assets/imgs/itinerario/itinerario_vietnam.jpg',
      imageMobile: '/assets/imgs/itinerario/itinerario_mobile_vietnam.jpg',
      name: 'Vietnam',
      subtitle: '10 días',
      button: true,
      link: 'vietnam',
    },
    {
      id: 6,
      image: '/assets/imgs/itinerario/itinerario_indonesia.jpg',
      imageMobile: '/assets/imgs/itinerario/itinerario_mobile_indonesia.jpg',
      name: 'Indonesia',
      subtitle: '8 días',
      button: true,
      link: 'indonesia',
    },
    {
      id: 7,
      image: '/assets/imgs/itinerario/itinerario_filipinas.jpg',
      imageMobile: '/assets/imgs/itinerario/itinerario_mobile_filipinas.jpg',
      name: 'Filipinas',
      subtitle: '8 días',
      button: true,
      link: 'filipinas',
    },
  ];
  public otrosDestinos: any[] = [
    {
      id: 1,
      image: '/assets/imgs/itinerario/otros_destinos_malasia.png',
      imageMobile: '/assets/imgs/itinerario/otros_mobile_malasia.png',
      name: 'Malasia y Singapur',
      subtitle: '6 días',
      button: false,
      // link: 'india',
    },
    {
      id: 1,
      image: '/assets/imgs/itinerario/otros_tanzania.jpg',
      imageMobile: '/assets/imgs/itinerario/otros_mobile_tanzania.png',
      name: 'Tanzania',
      subtitle: '10 días',
      button: false,
      // link: 'india',
    },
    {
      id: 1,
      image: '/assets/imgs/itinerario/otros_egipto.jpg',
      imageMobile: '/assets/imgs/itinerario/otros_mobile_egipto.png',
      name: 'Egipto',
      subtitle: '12 días',
      button: false,
      // link: 'india',
    },
    {
      id: 1,
      image: '/assets/imgs/itinerario/otros_turquia.jpg',
      imageMobile: '/assets/imgs/itinerario/otros_mobile_turquia.png',
      name: 'Turquía',
      subtitle: '7 días',
      button: false,
      // link: 'india',
    },
  ];

  public beneficiosItems: StandardItem[] = [
    {
      image: '/assets/imgs/beneficios/beneficios_dhl.jpg',
      title: 'DHL',
      description:
        'Con DHL venimos trabajando juntos de forma de poder brindar a quienes viajen un servicio fácil y económico para enviar encomiendas mientras viajan. DHL nos lleva al hotel las cajas con las medidas necesarias para hacer los envíos. Las llenamos de cosas para enviar a Uruguay, se paga el envío online y luego DHL levanta las cajas por el hotel para hacerlas llegar a Uruguay. Tenemos acordada una tarifa especial para los grupos de dTyT; 5kg - 60 usd el envío que llega en 10 días a Uruguay.',
    },
    /* {
      image: '/assets/imgs/beneficios/beneficios_openapp.jpg',
      title: 'Beneficios 2x1',
      description:
        'Para que la rifa sea atractiva, vendible y tenga beneficios, además de los sorteos, desarrollamos una APP de beneficios 2 x 1 o 50% de descuento en restaurantes, bares y otros servicios. Pueden verla y probarla descargándola en Android e IOs por el nombre “dTyT Beneficios”. A la APP accederán sin costo todas las personas que estén vendiendo rifas así pueden utilizarla y contarle la experiencia a los potenciales compradores de rifas.',
    }, */
    {
      image: '/assets/imgs/beneficios/beneficios_trota.jpg',
      title: '“Trotamundos: la tienda del viajero”',
      description:
        'Una tienda de accesorios pensada para viajerxs a disposición para nosotros y a los grupos. Tenemos descuento en todos sus accesorios entre 15% y 50% dependiendo del accesorio. Ubicada en: Bulevar España 2085 esq Maldonado.',
    },
    {
      image: '/assets/imgs/beneficios/beneficios_appviaje.jpg',
      title: 'APP de viaje',
      description:
        'Esta APP fue hecha hace unos años. Reúne toda la información del viaje. Estado de los vuelos que vamos a tomar en tiempo real, el itinerario día a día, las actividades y paseos que hay para hacer en cada ciudad, datos curiosos de cada lugar, y listados para sumarse a las actividades que organizamos ahí mismo. Está en versión Android e IOs. Nos ha resultado muy práctica y una herramienta de mucha utilidad tanto para los usuarios como para nosotros al momento de organizar bien cada paso. La pueden probar en estos días. Se descarga por el nombre “de Toque y Toque” e ingresan con el usuario: vr2022@dtyt.com y contraseña: vr2022',
    },
    {
      image: '/assets/imgs/beneficios/beneficios_buceo.jpg',
      title: 'Curso de Buceo',
      description:
        'Hacer Buceo es una de las actividades recomendadas durante el viaje, aunque sea probar una vez. Phi Phi, Bali, Gili, El Nido… son de los lugares preferidos por los buceadores, y justo por allí andaremos. Por eso hicimos un convenio con una escuela de buceo en Tailandia y con un instructor en Uruguay para hacer el curso en conjunto. Se hace el curso teórico y la práctica en la piscina en Uruguay, y se termina validando el curso práctico en las aguas de Tailandia, aprovechando el tiempo, la practicidad, el paisaje y el precio ya que nos estamos ahorrando de pagar el Buceo allá.',
    },
    {
      image: '/assets/imgs/beneficios/beneficios_fotografia.jpg',
      title: 'Curso de fotografía enfocado a viajes',
      description:
        'Virginia Londero, fotógrafa profesional, brinda un curso de fotografía de 3 meses enfocada 100% a viajes a un precio especial para los grupos de viaje. ideal para que aprovechemos a dejar los mejores recuerdos en nuestras cámaras.',
    },
  ];

  public chartItems: ChartItem[] = [
    {
      title: 'Propuesta de viaje',
      description:
        '¿Consideras atractiva la propuesta? O hubieses preferido que sea más estructurado y guiado?',
      chartLabels: ['Si', 'No'],
      chartData: ['97', '3'],
    },
    {
      title: 'Trato personal / profesional',
      description: '¿Cómo fue el trato hacia ti?',
      chartLabels: ['Muy bueno', 'Bueno'],
      chartData: ['83.2', '15.9'],
    },
    {
      title: 'Ubicación de Hoteles',
      description: '¿Cuál es tu opinión sobre la ubicación de cada hospedaje?',
      chartLabels: ['Muy buena', 'Buena'],
      chartData: ['92.8', '7.2'],
    },
    {
      title: 'Resultado personal',
      description:
        '¿Consideras al viaje como parte de un crecimiento personal?',
      chartLabels: ['Si', 'Otros'],
      chartData: ['97.4', '2.6'],
    },
    {
      title: 'Calificación general',
      description: '¿Cómo calificarías al viaje?',
      chartLabels: ['Muy bueno', 'Bueno'],
      chartData: ['93', '7'],
    },
    {
      title: 'Cumplimiento de servicio',
      description:
        'En base a lo que se habló, propuso y explicó en las reuniones y juntadas previas respecto a lo que iba a ser el viaje, ¿considerás que esas cosas se cumplieron?',
      chartLabels: ['Si', 'Otras'],
      chartData: ['93.9', '6.1'],
    },
  ];

  public groupsImages: SlideItem[] = [
    {
      desktop: '/assets/imgs/groups/femed_bookfotos_1.png',
      mobile: '/assets/imgs/groups/femed_bookfotos_1.png',
    },
    {
      desktop: '/assets/imgs/groups/femed_bookfotos_2.png',
      mobile: '/assets/imgs/groups/femed_bookfotos_2.png',
    },
    {
      desktop: '/assets/imgs/groups/femed_bookfotos_3.png',
      mobile: '/assets/imgs/groups/femed_bookfotos_3.png',
    },
    {
      desktop: '/assets/imgs/groups/femed_bookfotos_4.png',
      mobile: '/assets/imgs/groups/femed_bookfotos_4.png',
    },
    {
      desktop: '/assets/imgs/groups/femed_bookfotos_5.png',
      mobile: '/assets/imgs/groups/femed_bookfotos_5.png',
    },
    {
      desktop: '/assets/imgs/groups/femed_bookfotos_6.png',
      mobile: '/assets/imgs/groups/femed_bookfotos_6.png',
    },
    {
      desktop: '/assets/imgs/groups/femed_bookfotos_7.png',
      mobile: '/assets/imgs/groups/femed_bookfotos_7.png',
    },
    {
      desktop: '/assets/imgs/groups/femed_bookfotos_8.png',
      mobile: '/assets/imgs/groups/femed_bookfotos_8.png',
    },
    {
      desktop: '/assets/imgs/groups/femed_bookfotos_9.png',
      mobile: '/assets/imgs/groups/femed_bookfotos_9.png',
    },
    {
      desktop: '/assets/imgs/groups/femed_bookfotos_10.png',
      mobile: '/assets/imgs/groups/femed_bookfotos_10.png',
    },
    {
      desktop: '/assets/imgs/groups/femed_bookfotos_11.png',
      mobile: '/assets/imgs/groups/femed_bookfotos_11.png',
    },
  ];

  public academicoImages: SlideItem[] = [
    {
      desktop: '/assets/imgs/academico/academico_desktop_1.png',
      mobile: '/assets/imgs/academico/academico_mobile_1.png',
    },
    {
      desktop: '/assets/imgs/academico/academico_desktop_2.png',
      mobile: '/assets/imgs/academico/academico_mobile_2.png',
    },
    {
      desktop: '/assets/imgs/academico/academico_desktop_3.png',
      mobile: '/assets/imgs/academico/academico_mobile_3.png',
    },
  ];

  public tramos: TramoItem[] = [
    {
      price: '20 rifas',
      countries: 'India - Nepal',
      days: '17 días',
      rifas: 'USD 1590',
      name: 'Extensión A',
      main: false,
      content: `<strong>Incluye todo lo detallado en la sección "¿Qué incluye?"<br>Además para quienes realicen estos destinos se incluye:</strong><br><br>
      - Entrada y traslado al Taj Mahal<br>
- Trekking de 2 días / 1 noche en Dampus (Nepal), incluyendo guías, comidas y alojamiento en la montaña.<br>
- Día de recorrida de templos en Tuc Tuc.
`,
    },
    {
      price: '61 rifas',
      countries: 'Tailandia - Camboya - Vietnam',
      days: '27 días',
      rifas: 'USD 4890',
      name: 'Núcleo',
      main: true,
      content: `<strong>Incluye todo lo detallado en la sección "¿Qué incluye?"<br>Además se incluye:</strong><br><br>
      - Entrada y traslado a los templos de Angkor Wat y guía en Español.<br>
- Entrada y traslado a los Túneles de Cuchi.Guía en Español.<br>
- Crucero de 2 días / 1 noche en Halong Bay. Comidas incluídas.
`,
    },
    {
      price: '15 rifas',
      countries: 'Indonesia',
      days: '8 días',
      rifas: 'USD 1190',
      name: 'Extensión B',
      main: false,
      content: `<strong>Incluye todo lo detallado en la sección "¿Qué incluye?"</strong><br><br>`,
    },
    {
      price: '13,6 rifas',
      countries: 'Filipinas',
      days: '8 días',
      rifas: 'USD 1090',
      name: 'Extensión C',
      main: false,
      content: `<strong>Incluye todo lo detallado en la sección "¿Qué incluye?"</strong><br><br>`,
    },
  ];

  public premios: PremioItem[] = [
    {
      name: 'Autos 0 Km', /* 16  */
      image: '/assets/imgs/premios/premios_1.jpg',
      // subtitle: '3 autos por mes + 1 extra',
    },
    /* {
      image: '/assets/imgs/premios/beneficios_2x1.jpg',
      name: 'Beneficios 2x1',
    }, */
    {
      name: 'Viajes dobles a Madrid', /* 5 */
      image: '/assets/imgs/premios/premios_2.jpg',
      // subtitle: '1 viaje por mes',
    },
    {
      name: 'Viajes dobles a Nueva York', /* 5 */
      image: '/assets/imgs/premios/premios_3.jpg',
      // subtitle: '1 viaje por mes',
    },
    {
      name: 'Viajes dobles a Miami', /* 5 */
      image: '/assets/imgs/premios/premios_4.jpg',
      // subtitle: '1 viaje por mes',
    },
    {
      name: 'Viajes dobles a Lima', /* 10 */
      image: '/assets/imgs/premios/premios_5.jpg',
      // subtitle: '2 viajes por mes',
    },
    {
      name: 'Viajes dobles a Rio de Janeiro', /* 10 */
      image: '/assets/imgs/premios/premios_6.jpg',
      // subtitle: '2 viajes por mes',
    },
    {
      name: 'Viajes dobles a Santiago de Chile', /* 10 */
      image: '/assets/imgs/premios/premios_7.jpg',
      // subtitle: '2 viajes por mes',
    },
    {
      name: 'Viajes dobles a Buenos Aires', /* 10 */
      image: '/assets/imgs/premios/premios_8.jpg',
      // subtitle: '2 viajes por mes',
    },
  ];

  public rifasAccordion: AccordionItem[] = [
    {
      title: '¿Cuánto cuesta cada rifa y cuánto recaudo por cada rifa vendida?',
      content: `La rifa consta de 5 sorteos.<br>
Cada sorteo cuesta 20 usd, por lo que la rifa entera costará 100 usd.<br>
Al ser 5 sorteos distintos, la rifa tiene la posibilidad de venderse fraccionada a distintas personas, no teniendo que si o si un comprador tenga que pagar 100 usd. Es decir, se puede vender un sorteo a una persona y otro sorteo a otra.<br>
Obviamente que lo mejor es vender la rifa entera a un comprador sólo. Para esto se incentiva con un premio extra a quien compre toda la rifa entera.
<br><br>
Por cada Rifa, se recauda 80 usd y 20 usd son para el pago de los premios.
`,
    },
    { /* Puntualmente año a año se habla con las tarjetas para que habiliten la posibilidad de 10 o 12 cuotas sin recargo.<br> */
      title: '¿Cómo se puede pagar la rifa?',
      content: `La rifa se puede pagar tanto al contado como con tarjeta hasta 10
       cuotas sin recargo.<br>
Para el pago contado, cada estudiante tendrá una cuenta en Itau para realizar los depósitos.<br>
Para los pagos con tarjeta, se realizarán via web, de forma que sea simple y no tener que ir hasta donde se encuentre el comprador. Se realiza todo mediantes links y un sistema de gestión que controlará todas las ventas.
`,
    },
    {
      title:
        '¿Qué pasa si vendo más rifas de las que necesito para cubrir el viaje?',
      content: `Supongamos que el viaje cueste 4000 usd. Si quisiera pagarlo todo en rifas sería 50 rifas.<br>
Si un estudiante vende 60 rifas, recaudará 4800 usd. Esos 800 usd se darán en efectivo para que pueda utilizarlo durante el viaje.
`,
    },
    {
      title:
        '¿Qué pasa si vendo menos rifas de las que necesito para cubrir el viaje?',
      content: `En caso que con la venta de rifas no llegue a cubrir los costos del viaje (o decidas no vender rifas), la diferencia puede pagarse hasta en 6 cuotas sin recargo con Visa, Oca y Master.<br>
También está la posibilidad de hacer depósitos parciales en la cuenta de cada uno para ir ahorrando durante el año y medio que dura todo proceso, hasta llegar a la fecha del viaje.
`,
    },
  ];

  public preguntasAccordion: AccordionItem[] = [
    {
      title: '¿Cuándo es el viaje?',
      content: `El viaje de la generación ha comenzado históricamente sobre finales de febrero.<br>
El motivo es que hay un examen (Médica) que por lo general el período de Feb. es sobre el 20 de ese mes.<br>
A partir de ahí, se planea el viaje de 2 meses de duración. <br>
Quienes hagan todo el viaje, llegarían a Uruguay sobre finales de Abril, para ar luego la prueba del internado sobre mediados de Junio.<br>
Quienes decidan no hacer todo el viaje, retornarán antes.
`,
    },
    {
      title: '¿Viajamos toda la generación junta?',
      content: `En los años anteriores al ser 300 personas aprox por generación, lo que se ha decidido es dividir el grupo en 2 sub-grupos de 150 para lograr un viaje más descontracturado y fluido.<br>
Pero perfectamente se puede pensar en un viaje de toda la generación junta. Es cuestión de discutirlo entre todos más adelante viendo pros y contras.
`,
    },
    {
      title: '¿Cuándo tengo que decidir qué itinerario quiero realizar?',
      content: `La idea es que todos tengan tiempo suficiente para pensar y decidir qué viaje quieren hacer, ya que esta decisión depende de varios factores.<br>
Los destinos a realizar (y por ende la duración que tendrá el viaje de cada uno) se decide recién en Agosto 2021. <br>
Esto es debido a que a esa altura ya sabrán un poco más sobre la cantidad de rifas que vendieron, licencias, tiempos de estudios, etc y puedan tomar la decisión de la mejor manera posible.<br>
Hasta Agosto 2021, además de la venta de la rifa, lo que haremos serán juntadas para charlar de los destinos, el proceso de venta de rifas, e irnos conociendo bien de a poco pero sin frenarnos, ya que vamos a convivir juntos 2 meses de viaje y está lindo y es importante saber con quienes vamos a viajar.
`,
    },
    {
      title: '¿Hasta cuándo tengo tiempo para saldar el pago del viaje?',
      content: `El viaje deberá estar pago en su totalidad 2 meses antes de la salida.<br>
Mediante la venta de rifas, que se espera cubra todo el viaje, esto se cumpliría sin problemas ya que el último sorteo será a fines de Diciembre 2021.<br>
En caso que quede un resto por abonar, deberá ser sobre mediados de enero 2022 como máximo.
`,
    },
    {
      title: '¿Qué formas de recaudar tengo?',
      content: `- Rifas<br>
- After Offices en Cervecería Ramón<br>
- Recomendación a viajes dtyt<br>
- Club “dTyT” a disposición para eventos<br>
- Eventos privados en Lotus (Montevideo Shopping)
`,
    },
    {
      title: '¿Hasta cuándo me puedo dar de baja del viaje?',
      content: `Cualquier persona puede darse de baja en cualquier momento. Lo único que puede llegar a pasar es que dependiendo del momento en que decida darse de baja, ya se haya incurrido en costos que serán lo único que deba abonar esa persona.<br>
En caso que no se haya incurrido en costo alguno y la persona tenga dinero recaudado en la agencia, ese dinero quedará en la agencia y la persona podrá hacer uso del mismo luego.
`,
    },
    {
      title: '¿Puedo llevar un acompañante?',
      content: `Esto es algo que deberían votar en la generación.<br>
Por nuestra parte no hay ningún problema y de hecho en todas las generaciones anteriores ha existido la posibilidad de llevar 1 acompañante (novi@, amig@, herman@).<br>
Consideramos que lo ideal es no tener más de 1 acompañante, ya que si cada uno de ustedes decide llevar 5 amigxs de acompañantes que no son de la FMed, ya deja de ser un viaje de generación y pasa a ser un viaje de grupos de amigos que nada tiene que ver con el viaje de fin de curso.`,
    },
    {
      title: '¿Mi acompañante puede vender rifas?',
      content: `Si.<br>
Los acompañantes tienen los mismos “derechos” que los titulares. Tanto para la venta de rifas, como para la organización, elección de itinerario, precios, etc.
`,
    },
    {
      title:
        '¿Puedo ir a otros destinos previamente o posterior al viaje de la generación?',
      content: `Si claro! y ha pasado varias veces.<br>
Pasa mucho por ej. que pasan por Europa a visitar algún familiar, amigo, etc. O mismo deciden agregar algún destino extra para conocer.<br><br>

Puede pasar 2 situaciones:<br>
1 - Querer comenzar el viaje antes por algún otro destino y luego unirse al grupo de viaje de FMED para comenzar con el itinerario.<br>
2 - Luego de terminar el viaje de la FMED, continuar viajando por otros destinos.<br>
<br>
Cualquiera de las 2 cosas es posible y nosotros nos podemos encargar de organizar todo lo que tiene que ver con los vuelos que necesiten para hacer estas partes “extra”.
`,
    },
  ];

  public showVideo = false;
  public mobile = false;

  constructor(private gaService: GAService, @Inject(PLATFORM_ID) private platformId: any) {
    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener('hashchange', this.offsetAnchor);
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth <= 800) {
        this.mobile = true;
      }
      window.scrollTo(window.scrollX, window.scrollY - 150);
      this.gaService.pageView('/', 'Inicio Web');
    }
  }

  showPlayer() {
    this.showVideo = true;
  }

  hidePlayer() {
    this.showVideo = false;
  }

  offsetAnchor() {
    if (location.hash.length !== 0) {
      if (isPlatformBrowser(this.platformId)) {
        window.scrollTo(window.scrollX, window.scrollY - 150);
      }
    }
  }
}
