import { PaisItem } from '../interfaces/interfaces';
export class Paises {
         public india: PaisItem = {
           id: 1,
           image: '/assets/imgs/itinerario/itinerario_india.jpg',
           name: 'India',
           dias: '8 días',
           button: true,
           link: 'india',
           slides: [
             {
               desktop: '/assets/imgs/paises/india/book_india_1.jpg',
               mobile: '/assets/imgs/paises/india_mobile/book_india_1.jpg',
             },
             {
               desktop: '/assets/imgs/paises/india/book_india_2.jpg',
               mobile: '/assets/imgs/paises/india_mobile/book_india_2.jpg',
             },
             {
               desktop: '/assets/imgs/paises/india/book_india_3.jpg',
               mobile: '/assets/imgs/paises/india_mobile/book_india_3.jpg',
             },
             {
               desktop: '/assets/imgs/paises/india/book_india_4.jpg',
               mobile: '/assets/imgs/paises/india_mobile/book_india_4.jpg',
             },
             {
               desktop: '/assets/imgs/paises/india/book_india_5.jpg',
               mobile: '/assets/imgs/paises/india_mobile/book_india_5.jpg',
             },
             {
               desktop: '/assets/imgs/paises/india/book_india_6.jpg',
               mobile: '/assets/imgs/paises/india_mobile/book_india_6.jpg',
             },
           ],
           subtitle: 'Mimetizarnos',
           description:
             'Colores de tiendas y sarees por las calles, olores a especias, bosta, polvo, el sonido de bocinas, de idiomas, vendedores, de personas que saludan mezclados con imponentes templos, fuertes, religiones… un combo de sensaciones que quedarán en memorias de por vida.',
           ciudades: [
             {
               name: 'Delhi',
               image: '/assets/imgs/paises/india/ciudades_india_delhi.jpg',
               imageMobile:
                 '/assets/imgs/paises/india_mobile/ciudades_india_delhi.jpg',
               subtitle: '4 días',
             },
             {
               name: 'Agra',
               image: '/assets/imgs/paises/india/ciudades_india_agra.jpg',
               imageMobile:
                 '/assets/imgs/paises/india_mobile/ciudades_india_agra.jpg',
               subtitle: '1 día',
             },
             {
               name: 'Jaipur',
               image: '/assets/imgs/paises/india/ciudades_india_jaipur.jpg',
               imageMobile:
                 '/assets/imgs/paises/india_mobile/ciudades_india_jaipur.jpg',
               subtitle: '2 días',
             },
             {
               name: 'Varanasi',
               image: '/assets/imgs/paises/india/ciudades_india_varanasi.jpg',
               imageMobile:
                 '/assets/imgs/paises/india_mobile/ciudades_india_varanasi.jpg',
               subtitle: '2 días',
             },
           ],
           hoteles: [
             {
               subtitle: 'Hari Piorko',
               image: '/assets/imgs/paises/india/hoteles_india_delhi.jpg',
               imageMobile:
                 '/assets/imgs/paises/india_mobile/hoteles_india_delhi.jpg',
               name: 'Delhi',
               url: 'https://www.booking.com/hotel/in/hari-piorko.es.html',
               link: 'https://www.booking.com/hotel/in/hari-piorko.es.html',
             },
             {
               subtitle: 'Fort Chandragupt',
               image: '/assets/imgs/paises/india/hoteles_india_jaipur.jpg',
               imageMobile:
                 '/assets/imgs/paises/india_mobile/hoteles_india_jaipur.jpg',
               name: 'Jaipur',
               url:
                 'https://www.booking.com/hotel/in/fort-chandragupt-jaipur.es.html',
               link:
                 'https://www.booking.com/hotel/in/fort-chandragupt-jaipur.es.html',
             },
             {
               subtitle: 'New Broadway',
               image: '/assets/imgs/paises/india/hoteles_india_varanasi.jpg',
               imageMobile:
                 '/assets/imgs/paises/india_mobile/hoteles_india_varanasi.jpg',
               name: 'Varanasi',
               url: 'https://www.booking.com/hotel/in/new-broadway.es.html',
               link: 'https://www.booking.com/hotel/in/new-broadway.es.html',
             },
           ],
           transporte: [
             {
               image:
                 '/assets/imgs/paises/india_mobile/transportes_india_1.jpg',
               name: 'Delhi - Jaipur',
             },
             {
               image:
                 '/assets/imgs/paises/india_mobile/transportes_india_2.jpg',
               name: 'Jaipur - Varanasi',
             },
             {
               image:
                 '/assets/imgs/paises/india_mobile/transportes_india_3.jpg',
               name: 'Varanasi - Pokhara',
             },
           ],
         };

         public nepal: PaisItem = {
           id: 2,
           image: '/assets/imgs/itinerario/itinerario_nepal.jpg',
           name: 'Nepal',
           dias: '8 días',
           button: true,
           link: 'nepal',
           slides: [
             {
               desktop: '/assets/imgs/paises/nepal/book_nepal_1.jpg',
               mobile: '/assets/imgs/paises/nepal_mobile/book_nepal_1.jpg',
             },
             {
               desktop: '/assets/imgs/paises/nepal/book_nepal_2.jpg',
               mobile: '/assets/imgs/paises/nepal_mobile/book_nepal_2.jpg',
             },
             {
               desktop: '/assets/imgs/paises/nepal/book_nepal_3.jpg',
               mobile: '/assets/imgs/paises/nepal_mobile/book_nepal_3.jpg',
             },
             {
               desktop: '/assets/imgs/paises/nepal/book_nepal_4.jpg',
               mobile: '/assets/imgs/paises/nepal_mobile/book_nepal_4.jpg',
             },
             {
               desktop: '/assets/imgs/paises/nepal/book_nepal_5.jpg',
               mobile: '/assets/imgs/paises/nepal_mobile/book_nepal_5.jpg',
             },
           ],
           subtitle: 'Respirá',
           description:
             'Aire de montaña, ciudades y rutas a 1400 msnm, valles, lagos, verde… mucho verde. Un país para disfrutar de lugares donde comer muy bien y barato, de naturaleza, de deportes extremos, de paseos al aire libre, en una esfera donde la religión y sus gigantes vecinos influyen positiva (y no tanto) en su vida social y económica.',
           ciudades: [
             {
               name: 'Pokhara',
               image: '/assets/imgs/paises/nepal/ciudades_nepal_pokhara.jpg',
               imageMobile:
                 '/assets/imgs/paises/nepal_mobile/ciudades_nepal_pokhara.jpg',
               subtitle: '4 días',
             },
             {
               name: 'Dhampus Trekking',
               image: '/assets/imgs/paises/nepal/ciudades_nepal_dhampus.jpg',
               imageMobile:
                 '/assets/imgs/paises/nepal_mobile/ciudades_nepal_dhampus.jpg',
               subtitle: '1 días',
             },
             {
               name: 'Katmandu',
               image: '/assets/imgs/paises/nepal/ciudades_nepal_katmandu.jpg',
               imageMobile:
                 '/assets/imgs/paises/nepal_mobile/ciudades_nepal_katmandu.jpg',
               subtitle: '3 día',
             },
           ],
           hoteles: [
             {
               subtitle: 'Swiss International',
               image: '/assets/imgs/paises/nepal/hoteles_nepal_pokhara.jpg',
               imageMobile:
                 '/assets/imgs/paises/nepal_mobile/hoteles_nepal_pokhara.jpg',
               name: 'Pokhara',
               url:
                 'https://www.booking.com/hotel/np/swiss-international-sarowar-pokhara.es.html',
               link:
                 'https://www.booking.com/hotel/np/swiss-international-sarowar-pokhara.es.html',
             },
             {
               subtitle: 'Dhampus',
               image: '/assets/imgs/paises/nepal/hoteles_nepal_dhampus.jpg',
               imageMobile:
                 '/assets/imgs/paises/nepal_mobile/hoteles_nepal_dhampus.jpg',
               name: 'Dhampus',
               url: '',
               link: '',
             },
             {
               subtitle: 'Vaishali',
               image: '/assets/imgs/paises/nepal/hoteles_nepal_katmandu.jpg',
               imageMobile:
                 '/assets/imgs/paises/nepal_mobile/hoteles_nepal_katmandu.jpg',
               name: 'Katmandu',
               url:
                 'https://www.booking.com/hotel/np/vaishali-and-gaming-club.es.html',
               link:
                 'https://www.booking.com/hotel/np/vaishali-and-gaming-club.es.html',
             },
           ],
           transporte: [
             {
               image: '/assets/imgs/paises/nepal/transportes_nepal_1.jpg',
               imageMobile:
                 '/assets/imgs/paises/nepal_mobile/transportes_nepal_1.jpg',
               name: 'Pokhara - Katmandu',
             },
             {
               image: '/assets/imgs/paises/nepal/transportes_nepal_2.jpg',
               imageMobile:
                 '/assets/imgs/paises/nepal_mobile/transportes_nepal_2.jpg',
               name: 'Katmandu - Bangkok',
             },
           ],
         };

         public tailandia: PaisItem = {
           id: 3,
           image: '/assets/imgs/itinerario/itinerario_tailandia.jpg',
           name: 'Tailandia',
           dias: '10 días',
           button: true,
           link: 'tailandia',
           slides: [
             {
               desktop: '/assets/imgs/paises/tailandia/book_tailandia_1.jpg',
               mobile:
                 '/assets/imgs/paises/tailandia_mobile/book_tailandia_1.jpg',
             },
             {
               desktop: '/assets/imgs/paises/tailandia/book_tailandia_2.jpg',
               mobile:
                 '/assets/imgs/paises/tailandia_mobile/book_tailandia_2.jpg',
             },
             {
               desktop: '/assets/imgs/paises/tailandia/book_tailandia_3.jpg',
               mobile:
                 '/assets/imgs/paises/tailandia_mobile/book_tailandia_3.jpg',
             },
             {
               desktop: '/assets/imgs/paises/tailandia/book_tailandia_4.jpg',
               mobile:
                 '/assets/imgs/paises/tailandia_mobile/book_tailandia_4.jpg',
             },
             {
               desktop: '/assets/imgs/paises/tailandia/book_tailandia_5.jpg',
               mobile:
                 '/assets/imgs/paises/tailandia_mobile/book_tailandia_5.jpg',
             },
           ],
           subtitle: 'Pausa y ritmo',
           description:
             'Tailandia, llega y aparece como bocanada de descontractura. Después de los lugares que hemos atravesado y recorrido lo que nos atrae del país no es solo su cultura e historia, llegamos allí con ganas de soltar, de dejarnos ser… el entorno nos va a favorecer a eso porque ya quedamos anonadados con las fuertes historias de sus países vecinos. Desde el clima de 30 agradables grados por la noche, a lo que ofrece a lo largo y ancho del país. Aquí se reúnen viajeros de todo el mundo con distintas intenciones de viaje, pero reunidos aquí, para que cada uno siga luego con su deseo. A dejarse llevar… disfrutar del sur y sus islas o del norte con sus selvas y bosques.',
           ciudades: [
             {
               name: 'Bangkok',
               image:
                 '/assets/imgs/paises/tailandia/ciudades_tailandia_bangkok.jpg',
               imageMobile:
                 '/assets/imgs/paises/tailandia_mobile/ciudades_tailandia_bangkok.jpg',
               subtitle: '3 días',
             },
             {
               name: 'Phi Phi Island',
               image:
                 '/assets/imgs/paises/tailandia/ciudades_tailandia_phiphi.jpg',
               imageMobile:
                 '/assets/imgs/paises/tailandia_mobile/ciudades_tailandia_phiphi.jpg',
               subtitle: '6 días',
             },
             {
               name: 'Phuket',
               image:
                 '/assets/imgs/paises/tailandia/ciudades_tailandia_phuket.jpg',
               imageMobile:
                 '/assets/imgs/paises/tailandia_mobile/ciudades_tailandia_phuket.jpg',
               subtitle: '1 día',
             },
           ],
           hoteles: [
             {
               subtitle: 'Khaosan Palace',
               image:
                 '/assets/imgs/paises/tailandia/hoteles_tailandia_bangkok.jpg',
               imageMobile:
                 '/assets/imgs/paises/tailandia_mobile/hoteles_tailandia_bangkok.jpg',
               name: 'Bangkok',
               url:
                 'https://www.booking.com/hotel/th/khaosan-palace-inn.es.html',
               link:
                 'https://www.booking.com/hotel/th/khaosan-palace-inn.es.html',
             },
             {
               subtitle: 'Top View Resort',
               image:
                 '/assets/imgs/paises/tailandia/hoteles_tailandia_phiphi.jpg',
               imageMobile:
                 '/assets/imgs/paises/tailandia_mobile/hoteles_tailandia_phiphi.jpg',
               name: 'Phi Phi',
               url:
                 'https://www.booking.com/hotel/th/phi-phi-top-view-resort.es.htm',
               link:
                 'https://www.booking.com/hotel/th/phi-phi-top-view-resort.es.htm',
             },
             {
               subtitle: 'Ashlee HUB',
               image:
                 '/assets/imgs/paises/tailandia/hoteles_tailandia_phuket.jpg',
               imageMobile:
                 '/assets/imgs/paises/tailandia_mobile/hoteles_tailandia_phuket.jpg',
               name: 'Phuket',
               url:
                 'https://www.booking.com/hotel/th/centra-ashlee-patong.es.html',
               link:
                 'https://www.booking.com/hotel/th/centra-ashlee-patong.es.html',
             },
           ],
           transporte: [
             {
               image:
                 '/assets/imgs/paises/tailandia/transportes_tailandia_1.jpg',
               imageMobile:
                 '/assets/imgs/paises/tailandia_mobile/transportes_tailandia_1.jpg',
               name: 'Bangkok - Phi Phi',
             },
             {
               image:
                 '/assets/imgs/paises/tailandia/transportes_tailandia_2.jpg',
               imageMobile:
                 '/assets/imgs/paises/tailandia_mobile/transportes_tailandia_2.jpg',
               name: 'Phi Phi - Phuket',
             },
             {
               image:
                 '/assets/imgs/paises/tailandia/transportes_tailandia_3.jpg',
               imageMobile:
                 '/assets/imgs/paises/tailandia_mobile/transportes_tailandia_3.jpg',
               name: 'Phuket - Siem Reap',
             },
           ],
         };

         public camboya: PaisItem = {
           id: 4,
           image: '/assets/imgs/itinerario/itinerario_camboya.jpg',
           name: 'Camboya',
           dias: '4 días',
           button: true,
           link: 'camboya',
           slides: [
             {
               desktop: '/assets/imgs/paises/camboya/book_camboya_1.jpg',
               mobile: '/assets/imgs/paises/camboya_mobile/book_camboya_1.jpg',
             },
             {
               desktop: '/assets/imgs/paises/camboya/book_camboya_2.jpg',
               mobile: '/assets/imgs/paises/camboya_mobile/book_camboya_2.jpg',
             },
             {
               desktop: '/assets/imgs/paises/camboya/book_camboya_6.jpg',
               mobile: '/assets/imgs/paises/camboya_mobile/book_camboya_6.jpg',
             },
             {
               desktop: '/assets/imgs/paises/camboya/book_camboya_3.jpg',
               mobile: '/assets/imgs/paises/camboya_mobile/book_camboya_3.jpg',
             },
             {
               desktop: '/assets/imgs/paises/camboya/book_camboya_4.jpg',
               mobile: '/assets/imgs/paises/camboya_mobile/book_camboya_4.jpg',
             },
             {
               desktop: '/assets/imgs/paises/camboya/book_camboya_5.jpg',
               mobile: '/assets/imgs/paises/camboya_mobile/book_camboya_5.jpg',
             },
           ],
           subtitle: 'Inmiscuirnos en ',
           description:
             'Un país duramente castigado, contemporáneo, atravesando un genocidio hasta la intervención de su vecino Vietnam; historia que recorreremos y se ve por la calle en generaciones más entrada en años. Como sociedad se reivindicaron y están saliendo adelante a pesar de que el período posguerra les sigue pesando. El turismo hoy en día es su principal ingreso en el país y como pilar fundamental para motivar su visita a exploradores y turistas encontramos los templos y ruinas de Angkor Wat; colocadas incluso hasta en la bandera oficial de la nación Camboyana. Viajar por este país nos deja un efecto que reconoceremos recién una vez que volvamos a casa.',
           ciudades: [
             {
               name: 'Siem Reap',
               image:
                 '/assets/imgs/paises/camboya/ciudades_camboya_siemreap.jpg',
               imageMobile:
                 '/assets/imgs/paises/camboya_mobile/ciudades_camboya_siemreap.jpg',
               subtitle: '4 días',
             },
           ],
           hoteles: [
             {
               subtitle: 'Angkor Century Resort & Spa',
               image:
                 '/assets/imgs/paises/camboya/hoteles_camboya_siemreap.jpg',
               imageMobile:
                 '/assets/imgs/paises/camboya_mobile/hoteles_camboya_siemreap.jpg',
               name: 'Siem Reap',
               url:
                 'https://www.booking.com/hotel/kh/angkor-century-resort-spa.es.html',
               link:
                 'https://www.booking.com/hotel/kh/angkor-century-resort-spa.es.html',
             },
           ],
           transporte: [
             {
               image: '/assets/imgs/paises/camboya/transportes_camboya_1.jpg',
               imageMobile:
                 '/assets/imgs/paises/camboya_mobile/transportes_camboya_1.jpg',
               name: 'Siem Reap - Hanoi',
             },
           ],
         };

         public vietnam: PaisItem = {
           id: 5,
           image: '/assets/imgs/itinerario/itinerario_vietnam.jpg',
           name: 'Vietnam',
           dias: '10 días',
           button: true,
           link: 'vietnam',
           slides: [
             {
               desktop: '/assets/imgs/paises/vietnam/book_vietnam_1.jpg',
               mobile: '/assets/imgs/paises/vietnam_mobile/book_vietnam_1.jpg',
             },
             {
               desktop: '/assets/imgs/paises/vietnam/book_vietnam_2.jpg',
               mobile: '/assets/imgs/paises/vietnam_mobile/book_vietnam_2.jpg',
             },
             {
               desktop: '/assets/imgs/paises/vietnam/book_vietnam_3.jpg',
               mobile: '/assets/imgs/paises/vietnam_mobile/book_vietnam_3.jpg',
             },
             {
               desktop: '/assets/imgs/paises/vietnam/book_vietnam_4.jpg',
               mobile: '/assets/imgs/paises/vietnam_mobile/book_vietnam_4.jpg',
             },
             {
               desktop: '/assets/imgs/paises/vietnam/book_vietnam_5.jpg',
               mobile: '/assets/imgs/paises/vietnam_mobile/book_vietnam_5.jpg',
             },
           ],
           subtitle: 'Pesquisa',
           description:
             'Uno se va adentrando de a poco al país, al entorno, a la cultura y a la historia… ve y percibe que los veteranos que andan ahí caminando entre nosotros son personas que vivieron una de las guerras más largas y crudas de la historia. La sobrellevaron, se mantuvieron parados. Y ahora, sin resentimiento ni rencor abren sus puertas al mundo para disfrutar de todo lo que son y naturalmente tienen. Por las noches nos abrazan con luces y lámparas de colores, sutiles, hermosas. Un clima tropical que acompaña. Y precios ridículos para adquirir lo que nos imaginemos...',
           ciudades: [
             {
               name: 'Hanoi',
               image: '/assets/imgs/paises/vietnam/ciudades_vietnam_hanoi.jpg',
               imageMobile:
                 '/assets/imgs/paises/vietnam_mobile/ciudades_vietnam_hanoi.jpg',
               subtitle: '3 días',
             },
             {
               name: 'Halong Bay',
               image: '/assets/imgs/paises/vietnam/ciudades_vietnam_halong.jpg',
               imageMobile:
                 '/assets/imgs/paises/vietnam_mobile/ciudades_vietnam_halong.jpg',
               subtitle: '2 días',
             },
             {
               name: 'Hoian',
               image: '/assets/imgs/paises/vietnam/ciudades_vietnam_hoian.jpg',
               imageMobile:
                 '/assets/imgs/paises/vietnam_mobile/ciudades_vietnam_hoian.jpg',
               subtitle: '3 día',
             },
             {
               name: 'Ho Chi Minh',
               image: '/assets/imgs/paises/vietnam/ciudades_vietnam_hochi.jpg',
               imageMobile:
                 '/assets/imgs/paises/vietnam_mobile/ciudades_vietnam_hochi.jpg',
               subtitle: '2 días',
             },
           ],
           hoteles: [
             {
               subtitle: 'May de Ville Old Quarter',
               image: '/assets/imgs/paises/vietnam/hoteles_vietnam_hanoi.jpg',
               imageMobile:
                 '/assets/imgs/paises/vietnam_mobile/hoteles_vietnam_hanoi.jpg',
               name: 'Hanoi',
               url:
                 'https://www.booking.com/hotel/vn/may-de-ville-hanoi-capital.es.html',
               link:
                 'https://www.booking.com/hotel/vn/may-de-ville-hanoi-capital.es.html',
             },
             {
               subtitle: 'Crucero',
               image: '/assets/imgs/paises/vietnam/hoteles_vietnam_halong.jpg',
               imageMobile:
                 '/assets/imgs/paises/vietnam_mobile/hoteles_vietnam_halong.jpg',
               name: 'Halong Bay',
             },
             {
               subtitle: 'Acacia Hotel',
               image: '/assets/imgs/paises/vietnam/hoteles_vietnam_hoian.jpg',
               imageMobile:
                 '/assets/imgs/paises/vietnam_mobile/hoteles_vietnam_hoian.jpg',
               name: 'Hoian',
               url: 'https://www.booking.com/hotel/vn/vanloi.es.html',
               link: 'https://www.booking.com/hotel/vn/vanloi.es.html',
             },
             {
               subtitle: 'Golden Center',
               image: '/assets/imgs/paises/vietnam/hoteles_vietnam_hochi.jpg',
               imageMobile:
                 '/assets/imgs/paises/vietnam_mobile/hoteles_vietnam_hochi.jpg',
               name: 'Ho chi minh',
               url: 'https://www.booking.com/hotel/vn/golden-saigon.es.html',
               link: 'https://www.booking.com/hotel/vn/golden-saigon.es.html',
             },
           ],
           transporte: [
             {
               image: '/assets/imgs/paises/vietnam/transportes_vietnam_1.jpg',
               imageMobile:
                 '/assets/imgs/paises/vietnam_mobile/transportes_vietnam_1.jpg',
               name: 'Hanoi - Hoian',
             },
             {
               image: '/assets/imgs/paises/vietnam/transportes_vietnam_2.jpg',
               imageMobile:
                 '/assets/imgs/paises/vietnam_mobile/transportes_vietnam_2.jpg',
               name: 'Hoian - Ho Chi Minh',
             },
             {
               image: '/assets/imgs/paises/vietnam/transportes_vietnam_3.jpg',
               imageMobile:
                 '/assets/imgs/paises/vietnam_mobile/transportes_vietnam_3.jpg',
               name: 'Ho Chi Minh - Bali',
             },
           ],
         };

         public indonesia: PaisItem = {
           id: 6,
           image: '/assets/imgs/itinerario/itinerario_indonesia.jpg',
           name: 'Indonesia',
           dias: '8 días',
           button: true,
           link: 'indonesia',
           slides: [
             {
               desktop: '/assets/imgs/paises/indonesia/book_indonesia_1.jpg',
               mobile:
                 '/assets/imgs/paises/indonesia_mobile/book_indonesia_1.jpg',
             },
             {
               desktop: '/assets/imgs/paises/indonesia/book_indonesia_2.jpg',
               mobile:
                 '/assets/imgs/paises/indonesia_mobile/book_indonesia_2.jpg',
             },
             {
               desktop: '/assets/imgs/paises/filipinas/book_filipinas_3.jpg',
               mobile:
                 '/assets/imgs/paises/filipinas_mobile/book_filipinas_3.jpg',
             },
             {
               desktop: '/assets/imgs/paises/indonesia/book_indonesia_3.jpg',
               mobile:
                 '/assets/imgs/paises/indonesia_mobile/book_indonesia_3.jpg',
             },
             {
               desktop: '/assets/imgs/paises/indonesia/book_indonesia_4.jpg',
               mobile:
                 '/assets/imgs/paises/indonesia_mobile/book_indonesia_4.jpg',
             },
             {
               desktop: '/assets/imgs/paises/indonesia/book_indonesia_5.jpg',
               mobile:
                 '/assets/imgs/paises/indonesia_mobile/book_indonesia_5.jpg',
             },
           ],
           subtitle: 'Exotismo e imperfección',
           description:
             'Te imagino en moto, en bicicleta, de sur a norte, de playas y acantilados, a selvas y volcanes. Tu vida y cultura musulmana con vestigios hinduistas y budistas, con esculturas muy propias tuyas, escondidas en bosques y al borde de abismos… Tu gente, tan increíblemente pura y sencilla, como aquellos estafadores que a su vez también te hacen tan particular… Ya nos encontraremos… para recorrerte también cuando cae el sol y se encienden las luces coloridas…',
           ciudades: [
             {
               name: 'Bali',
               image:
                 '/assets/imgs/paises/indonesia/ciudades_indonesia_bali.jpg',
               imageMobile:
                 '/assets/imgs/paises/indonesia_mobile/ciudades_indonesia_bali.jpg',
               subtitle: '5 días',
             },
             {
               name: 'Gili',
               image:
                 '/assets/imgs/paises/indonesia/ciudades_indonesia_gili.jpg',
               imageMobile:
                 '/assets/imgs/paises/indonesia_mobile/ciudades_indonesia_gili.jpg',
               subtitle: '3 días',
             },
           ],
           hoteles: [
             {
               subtitle: 'Aston Canggu',
               image:
                 '/assets/imgs/paises/indonesia/hoteles_indonesia_bali.jpg',
               imageMobile:
                 '/assets/imgs/paises/indonesia_mobile/hoteles_indonesia_bali.jpg',
               name: 'Bali',
               url:
                 'https://www.booking.com/hotel/id/aston-canggu-beach-resort.es.html',
               link:
                 'https://www.booking.com/hotel/id/aston-canggu-beach-resort.es.html',
             },
             {
               subtitle: 'Aston Sunset Beach resort',
               image:
                 '/assets/imgs/paises/indonesia/hoteles_indonesia_gili.jpg',
               imageMobile:
                 '/assets/imgs/paises/indonesia_mobile/hoteles_indonesia_gili.jpg',
               name: 'Gili',
               url:
                 'https://www.booking.com/hotel/id/aston-sunset-beach-resort-gili-trawangan.es.html',
               link:
                 'https://www.booking.com/hotel/id/aston-sunset-beach-resort-gili-trawangan.es.html',
             },
           ],
           transporte: [
             {
               image:
                 '/assets/imgs/paises/indonesia/transportes_indonesia_1.jpg',
               imageMobile:
                 '/assets/imgs/paises/indonesia_mobile/transportes_indonesia_1.jpg',
               name: 'Bali - Gili',
             },
             {
               image:
                 '/assets/imgs/paises/indonesia/transportes_indonesia_2.jpg',
               imageMobile:
                 '/assets/imgs/paises/indonesia_mobile/transportes_indonesia_2.jpg',
               name: 'Bali - Filipinas',
             },
           ],
         };

         public filipinas: PaisItem = {
           id: 7,
           image: '/assets/imgs/itinerario/itinerario_filipinas.jpg',
           name: 'Filipinas',
           dias: '8 días',
           button: true,
           link: 'filipinas',
           slides: [
             {
               desktop: '/assets/imgs/paises/filipinas/book_filipinas_1.jpg',
               mobile:
                 '/assets/imgs/paises/filipinas_mobile/book_filipinas_1.jpg',
             },
             {
               desktop: '/assets/imgs/paises/filipinas/book_filipinas_2.jpg',
               mobile:
                 '/assets/imgs/paises/filipinas_mobile/book_filipinas_2.jpg',
             },
            //  {
            //    desktop: '/assets/imgs/paises/filipinas/book_filipinas_3.jpg',
            //    mobile:
            //      '/assets/imgs/paises/filipinas_mobile/book_filipinas_3.jpg',
            //  },
             {
               desktop: '/assets/imgs/paises/filipinas/book_filipinas_4.jpg',
               mobile:
                 '/assets/imgs/paises/filipinas_mobile/book_filipinas_4.jpg',
             },
             {
               desktop: '/assets/imgs/paises/filipinas/book_filipinas_5.jpg',
               mobile:
                 '/assets/imgs/paises/filipinas_mobile/book_filipinas_5.jpg',
             },
             {
               desktop: '/assets/imgs/paises/filipinas/book_filipinas_6.jpg',
               mobile:
                 '/assets/imgs/paises/filipinas_mobile/book_filipinas_6.jpg',
             },
           ],
           subtitle: 'Delirio, quimera,',
           description:
             'Una vez me quedé dormido… de repente…. era de día… Y tuve de los sueños más lindos… era yo, en una playa, acostado en una hamaca paraguaya… escuchando al océano que me miraba con ojos turquesas…. entre decenas de islas, cuál de ellas más agreste y virgen… me levantaba y las recorría, me tiraba al agua, el tiempo no pasaba, había música de Bob Marley de fondo, los que vivían ahí me invitaban a bailar… era algo soñado!! Hasta que ZAS!, me desperté! No era un sueño… era un simple recuerdo de ayer en Filipinas…',
           ciudades: [
             {
               name: 'Puerto Princesa',
               image:
                 '/assets/imgs/paises/filipinas/ciudades_filipinas_puerto.jpg',
               imageMobile:
                 '/assets/imgs/paises/filipinas_mobile/ciudades_filipinas_puerto.jpg',
               subtitle: '2 días',
             },
             {
               name: 'El Nido',
               image:
                 '/assets/imgs/paises/filipinas/ciudades_filipinas_nido.jpg',
               imageMobile:
                 '/assets/imgs/paises/filipinas_mobile/ciudades_filipinas_nido.jpg',
               subtitle: '6 días',
             },
           ],
           hoteles: [
             {
               subtitle: 'Go Hotel',
               image:
                 '/assets/imgs/paises/filipinas/hoteles_filipinas_puerto.jpg',
               imageMobile:
                 '/assets/imgs/paises/filipinas_mobile/hoteles_filipinas_puerto.jpg',
               name: 'Puerto Princesa',
               url:
                 'https://www.booking.com/hotel/ph/go-hotels-puerto-princesa.es-ar.html',
               link:
                 'https://www.booking.com/hotel/ph/go-hotels-puerto-princesa.es-ar.html',
             },
             {
               subtitle: 'El Nido',
               image:
                 '/assets/imgs/paises/filipinas/hoteles_filipinas_nido.jpg',
               imageMobile:
                 '/assets/imgs/paises/filipinas_mobile/hoteles_filipinas_nido.jpg',
               name: 'El Nido',
               url: 'https://www.booking.com/hotel/ph/coral-cliff.es.html',
               link: 'https://www.booking.com/hotel/ph/coral-cliff.es.html',
             },
           ],
           transporte: [
             {
               image:
                 '/assets/imgs/paises/filipinas/transportes_filipinas_1.jpg',
               imageMobile:
                 '/assets/imgs/paises/filipinas_mobile/transportes_filipinas_1.jpg',
               name: 'Manila - El Nido',
             },
             {
               image:
                 '/assets/imgs/paises/filipinas/transportes_filipinas_2.jpg',
               imageMobile:
                 '/assets/imgs/paises/filipinas_mobile/transportes_filipinas_2.jpg',
               name: 'El Nido - Manila',
             },
           ],
         };
       }
