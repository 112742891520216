import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { PaisComponent } from './pages/pais/pais.component';
import { GraciasComponent } from './pages/gracias/gracias.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent,
  },
  {
    path: 'pais/:country',
    component: PaisComponent,
    // data: {country: 'india'}
  },
  {
    path: 'gracias',
    component: GraciasComponent,
    // data: {country: 'india'}
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
