import { Component, HostListener, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Vuelan Recibidos 2025 | de Toque y Toque';

  public showFloatingFooter = false;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  ngOnInit() {}

  @HostListener('window:scroll', ['$event'])
  doSomething(event) {
    if (isPlatformBrowser(this.platformId)) {
      if (window.pageYOffset > 150) {
        this.showFloatingFooter = true;
        if (
          window.innerHeight + window.scrollY + 300 >=
          document.body.offsetHeight
        ) {
          this.showFloatingFooter = false;
        } else {
          this.showFloatingFooter = true;
        }
      } else {
        this.showFloatingFooter = false;
      }
    }
  }
}
