import { Component, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { GAService } from '../../services/gaservice.service';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-gracias',
  templateUrl: './gracias.component.html',
  styleUrls: ['./gracias.component.scss'],
})
export class GraciasComponent implements OnInit {
  public innerWidth: any;
  public mobile: boolean = false;

  public transporteBreakpoints = {
    640: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    801: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private gaService: GAService,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.innerWidth = window.innerWidth;
    }

    if (this.innerWidth <= 800) {
      this.mobile = true;
    }

    setTimeout(() => {
      this.document.location.href = 'https://vuelanrecibidos.com/';
    }, 10000);

    window.scroll(0, 0);
  }
}
