<router-outlet></router-outlet>
<div class="floating-footer" *ngIf="showFloatingFooter">
    <div id="quiero-info-container" class="bottom">
        <a href="#queressabermas" target="_blank"> <!-- "https://wa.me/59892443898?text=Hola+%22dTyT%22%21%0D%0AMe+gustar%C3%ADa+recibir+informaci%C3%B3n+sobre+el+viaje+%22Experiencia+al+Mundo%22%0D%0AM%C3%A9tanle+en+la+rapidez+de+la+respuesta%21%0D%0ASi+me+gusta+la+propuesta+les+vuelvo+a+escribir.%0D%0AGracias%21%21"
        -->
            <div id="quiero-info">
              REUNIÓN INFORMATIVA
            </div>
        </a>
    </div>
    <div class="wp-button">
        <a href="https://wa.me/59892443898?text=Hola%2C+quiero+saber+más+sobre+Vuelan+Recibidos%21"
            target="_blank">
            <img src="/assets/imgs/wp.png" alt="">
        </a>
    </div>
</div>
