import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormService } from '../../services/form.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  initForm: boolean = true;

  public formError = false;
  public sending = false;
  public sended = false;
  public myRecaptcha = false;
  public name = '';
  public phone = '';
  public mail = '';

  @Input() gracias = false;

  constructor(private formService: FormService, private router: Router) {}

  ngOnInit(): void {
    if (this.gracias) {
      this.initForm = false;
    }
  }

  validateForm() {
    if(this.mail == '' || this.phone == '' || this.mail == '') {
      return false;
    }
    return true;
  }

  enviar() {
    this.formError = false;
    if(this.validateForm()) {
      this.sending = true;
      this.formService.send(this.name, this.phone, this.mail).subscribe( (res: any) => {
        this.sending = false;
        
        if ( res.status == 'success') {
          this.sended = true;
          this.router.navigateByUrl("/gracias");
        }
      });
    } else {
      this.formError = true;
    }
  }

  onScriptLoad(){}
  onScriptError(){}
}
