<app-main-slide></app-main-slide>

<div id="first-section" class="main-content top-rounded">
    <div class="section-wrapper">

      <div class="content-section">
        <div class="section-title" wmAnimate="fadeIn" speed="faster" aos once>
            ¿Qué es Vuelan Recibidos?
        </div>
        <div class="section-text">
            <p>
              Arquitectura tiene su viaje, Ciencias Económicas también… ¿Por qué el resto no? De esta pregunta nace Vuelan Recibidos.
              Un viaje pensado para que todas las facultades de Uruguay tengan la oportunidad de viajar <strong>vendiendo rifas</strong> luego de terminar su carrera. <br><br>

              Vuelan Recibidos es una fascinante aventura de 2 meses por el sudeste asiático, donde nuestros sueños y anhelos se funden con los lugares más exóticos del mundo,
              creando experiencias y vivencias que perdurarán para siempre.
              </p>
        </div>
      </div>

        <div class="content-section" wmAnimate="fadeIn" speed="faster" aos once (click)="showPlayer()">
          <!-- <div class="section-text">
            <p>REUNIÓN DE VIAJE el día Miércoles 27 de Setiembre a las 19 hrs.</p>
          </div> -->
            <div class="video-img">
                <img src="/assets/imgs/img-video.jpg" alt="">
            </div>
            <div class="video-img-mobile">
                <img src="/assets/imgs/img-video-mobile.jpg" alt="">
            </div>
            <div class="video-arrow">
                <img src="/assets/imgs/video-arrow.png" alt="">
            </div>
        </div>
        <div class="content-section">
            <div class="section-title" wmAnimate="fadeIn" speed="faster" aos once>
                <!-- Itinerario -->
                ¿A dónde nos vamos?
            </div>
            <div class="section-text">
                <p>
                    <!-- Un día a día pensado y planeado, contemplando la cantidad de días necesarios en cada lugar, teniendo en cuenta el orden de la visita a los distintos países para que se disfruten como se debe (Ej: ir a India al principio es fundamental.) Como verán en
                    el siguiente cuadro, encontraremos 2 bloques de viaje; El “Núcleo” y “Extensiones”.<br> El Núcleo es la parte del viaje clave y central, donde reúne lo esencial de una visita por Asia. Playa, cultura, historia,
                    noche, lindos paseos, etc, mientras que las extensiones son para que puedan elegir alargar el viaje algunos días según sus gustos, tiempos, preferencias, presupuesto, etc. Para la elección de las extensiones los invitamos a reunirnos
                    las veces que sean necesarias en la oficina y a su vez en la “Parte 3” del PDF tendrán información acerca de todos los destinos para que tengan referencias.<br> Que lo disfruten! -->
                    Imaginá un viaje que va más allá de lo convencional, un viaje que despierta tus sentidos y te transforma en el protagonista de una historia inolvidable.
                    Vuelan Recibidos es precisamente eso: una experiencia que trasciende los límites y te sumerge en los destinos más mágicos y enigmáticos del sudeste asiático. <br><br>

                    A lo largo de los años, hemos perfeccionado y refinado cada aspecto de este viaje, creando un itinerario que combina a la perfección la exploración cultural,
                    la conexión con la naturaleza, las grandes ciudades, ¿y por qué no? las risas con cervezas de por medio.
                  </p>
            </div>
            <div id="swiper-itinerario" class="countries-swiper-container">
                <app-swiper-image-text title="" [listadoItems]="paises" [mobile]="mobile" theme="dark"></app-swiper-image-text>
            </div>
        </div>

   <!-- <div class="content-section">
            <div class="section-title" wmAnimate="fadeIn" speed="faster" aos once>
                ¿Qué es Vuelan Recibidos?
            </div>
            <div class="section-text">
                <p>
                    Es un viaje que aúna a estudiantes de diferentes Facultades y/o Universidades a vivir una experiencia no turística por India, Nepal, Tailandia, Camboya, Vietnam, Indonesia y Filipinas. Combinamos el aprovechar los beneficios de viajar en grupo con hacer
                    cada un@ el viaje que esté buscando.<br><br> Cada cual elegirá los destinos que prefiera, la cantidad de días que le convengan y a su vez el presupuesto que quiera destinarle al viaje. <strong>Para que tod@s alcancen
                    el objetivo, ofrecemos rifas con premios realmente buenos, certificados por Loterías y Quinielas del Uruguay y que a su vez tienen premios directos solo por el hecho de haberles comprado y colaborado con la rifa.</strong><br><br>                    Aquí van más detalles del resto de la organización... Bienvenid@s!
                </p>
            </div>
        </div> -->

        <!-- <div class="content-section">
            <div class="section-title" wmAnimate="fadeIn" speed="faster" aos once>
                Yendo
            </div>
            <div class="section-text">
                <p>
                    El viaje está empezando, en este preciso momento, cuando lo imaginamos, cuando lo soñamos, cuando veo con quiénes lo voy a compartir… de repente me encuentro armando las cosas para subirme al avión, y más rápido de lo que pensaba, ya estamos en el aeropuerto.
                    Ahora aterrizados, llegamos! Empezamos a recorrer la ruta que hasta hace unos días nos acompañaba mientras dormíamos… <br>La idea es que el viaje se deslice, que sea fluido, que no sea una excursión. Encontramos
                    y aprendimos todas las instancias del viaje donde aprovechamos las ventajas de viajar en grupo, disfrutarlas y que fluyan. A su vez, algo importante y fundamental del viaje, es tener incontables momentos con mi grupo de amigas y amigos
                    y hasta incluso solx si se quiere, con la confianza y tranquilidad de estar contenidos y protegidos, mientras organizamos y preparamos los próximos movimientos y actividades del grupo.
                </p>
            </div>
        </div> -->
    </div>
</div>

<div id="second-section" class="main-content top-rounded">
    <div id="el-viaje" class="section-wrapper">
        <div class="great-title" wmAnimate="fadeIn" speed="faster" aos once>
          ¡Vendé rifas y viajá!
        </div>
        <div class="section-sub-title">
          Sobre la rifa
        </div>
        <div class="section-text">
                <p>
                  Al igual que Arquitectura y Economía, Vuelan Recibidos también se financia con rifas.
                  Reconocemos que sin ellas, este viaje sería simplemente un sueño inalcanzable. Por eso, nos hemos esforzado en crear la mejor rifa,
                  con premios increíbles y con alta probabilidad de ganar, para que la venta sea sencilla y se obtengan las ganancias necesarias.
                  Además, es una rifa hecha especialmente para este viaje y está aprobada por la Dirección Nacional de Loterías y Quinielas. <br><br>

                  Lo maravilloso de esta oportunidad es que no tendrás que pasar años vendiendo rifas. Por lo general, la venta no dura más de 4 o 5 meses,
                  lo cual te permitirá concentrarte en el final de tu carrera sin perder tiempo. <br><br>

                 <strong> La venta de rifas es opcional. Se puede solicitar la cantidad de rifas que sientan necesarias o incluso viajar pagando el viaje sin vender rifas. </strong>
                </p>
        </div>

        <div class="banner banner-desktop" wmAnimate="fadeIn" speed="faster" aos once>
          <img src="/assets/imgs/rifa-banner.png" alt="">
        </div>
        <div class="banner banner-mobile" wmAnimate="fadeIn" speed="faster" aos once>
            <img src="/assets/imgs/rifa-banner.png" alt="">
        </div>

        <div class="content-section">
            <div class="section-sub-title" wmAnimate="fadeIn" speed="faster" aos once>
              ¿Cuántas rifas tengo que vender para hacerlo posible?
            </div>
        </div>
        <div class="content-section">
            <div class="tramos-container">
                <app-tramos [tramos]="tramos"></app-tramos>
            </div>
        </div>

        <div class="content-section">
            <app-swiper-small title="¿Qué está incluido en el viaje?"
                [listadoItems]="incluyeGeneralItems"
                 description="En los precios que les mostramos antes decidimos incluir actividades y todas las cosas necesarias, hasta la fecha,
                que sí o sí se terminan gastando en el viaje con el fin de que puedan calcular bien cuánto llevar para gastar y que no hayan sorpresas."
                theme="light"></app-swiper-small> <!-- Se sacó description="En los precios antedichos decidimos incluir actividades y todas las cosas necesarias
                  que sí o sí se terminan gastando en el viaje con el fin de que puedan calcular bien cuánto llevar para gastar y que no haya sorpresas." -->
        </div>

        <div class="content-section">
          <div class="section-title" wmAnimate="fadeIn" speed="faster" aos once>
            Juntá a tu generación: ¡cuanto más sean, más ahorran!
          </div>
          <div class="section-text">
              <p>
                  En caso que sean varios interesados de una misma facultad, existirá un descuento en base a la cantidad de personas que sean:
                  <br><br> - Si son mas de 10 personas: 150 usd de descuento por persona<br> - Si son mas de 20 personas: 200 usd de descuento por persona<br>
                   - Si son mas de 30 personas: 300 usd de descuento por persona<br>
              </p>
          </div>
        </div>
        <!-- <div class="others-swiper-container">
            <div class="section-title" wmAnimate="fadeIn" speed="faster" aos once>
                Otros destinos
            </div>
            <div class="section-text">
                <p>
                    En base a comentarios, sugerencias y entusiasmos de algunos viajeros anteriores, que están al tanto de los viajes que realizamos año a año, se nos ocurrió agregarle al viaje de Medicina 1 o 2 semanas antes de empezar en India y los destinos que se
                    nos ocurren son 3:
                </p>
            </div>
            <app-swiper-image-text title="" [listadoItems]="otrosDestinos" [mobile]="mobile" theme="dark"></app-swiper-image-text>
        </div> -->
        <div id="rifas" class="content-section">
            <!-- <div class="section-title" wmAnimate="fadeIn" speed="faster" aos once>
                Sobre la rifa
            </div>
            <div class="section-text">
                <p>
                    Para hacer este hermoso y apasionante viaje sabemos lo necesario que es financiarlo a través de rifas. Si bien lo protagónico en el proceso del viaje son los destinos, los paseos, el grupo y más detalles, sin la rifa nada de esto sería viable. Es por
                    esto que nos esmeramos en hacer la mejor rifa, con los mejores premios, con altas probabilidades de ganar
                    para que la venta se haga más sencilla y se obtengan las ganancias esperadas.
                </p>
            </div> -->

            <div class="premios-swiper-container">
                <app-swiper-image-text title="Premios" [listadoItems]="premios"></app-swiper-image-text> <!--  y beneficios 2x1 -->
            </div>
            <div class="rifas-accordion-wrapper accordion-wrapper">
              <div class="section-title">Preguntas frecuentes sobre las rifas</div><br><br>
                <app-accordion [data]="rifasAccordion"></app-accordion>
            </div>
            <!-- <div class="section-text">
                &nbsp;*Si tenés más dudas sobre la rifa, podrás encontrar más información en nuestra propuesta en PDF
            </div> -->
        </div>
    </div>
</div>


<div id="third-section" class="main-content top-rounded">

  <div id="el-viaje" class="section-wrapper">
  <div class="content-section">
    <app-swiper-small title="¿Cómo lo vivieron las generaciones anteriores?"
        [listadoItems]="opinionesDeViajeros" description="Luego de cada viaje, les pedimos a los pasajeros que nos hagan una devolución de qué les pareció el viaje, estos son algunos comentarios:"
        theme="light">
    </app-swiper-small>
  </div>
  </div>
  <!-- div class="section-wrapper">
      <div class="content-section">
          <div id="alianzas-convenios" class="section-title" wmAnimate="fadeIn" speed="faster" aos once>
              Alianzas y Convenios
          </div>
          <div class="section-text">
              <p>
                  A continuación les presentamos los convenios que tenemos con diferentes proveedores para agregarle valor al viaje. Son proveedores que sí o sí van a ser utilizados por distintas personas dependiendo de sus gustos y necesidades y con los cuales hemos generado
                  una relación de trabajo muy práctica y efectiva.
              </p>
          </div>

          <div class="countries-swiper-container desktop">
              <div class="beneficio-item" *ngFor="let beneficio of beneficiosItems">

                  <div class="beneficio-left">
                      <div class="beneficio-image">
                          <img [src]="beneficio.image" alt="">
                      </div>
                  </div>

                  <div class="beneficio-right">
                      <div class="beneficio-title">
                          {{ beneficio.title }}
                      </div>
                      <div class="beneficio-description">
                          {{ beneficio.description }}
                      </div>
                  </div>

              </div>
          </div>

          <div class="countries-swiper-container mobile">
              <app-swiper-small title="" [listadoItems]="beneficiosItems" theme="light"></app-swiper-small>
          </div>
      </div>
  </div>
</div>

<div class="content-section">

  <div class="charts-swiper-container">
      <app-swiper-chart title="Encuestas de años anteriores" [listadoItems]="chartItems" theme="dark">
      </app-swiper-chart>
  </div>

  <div class="mas-encuestas">
      <a href="https://bit.ly/dTyT_EncMed22" target="_blank">
          <div class="mas-encuestas-btn">
              Ver más encuestas
          </div>
      </a>
  </div> -->

  <div id="el-viaje" class="section-wrapper">
    <div class="content-section">
      <div class="group-images-swiper" wmAnimate="fadeIn" speed="faster" aos once>
          <app-swiper-large-image [listadoItems]="groupsImages" [mobile]="mobile"></app-swiper-large-image>
      </div>
    </div>
  </div>

<!-- </div> -->

<div class="modal-player-wrapper" *ngIf="showVideo">
    <div class="close-video" (click)="hidePlayer()">
        <img src="/assets/imgs/close.svg" alt="">
    </div>
    <div class="modal-player">
        <app-youtube videoId="EST1iqRWNQY"></app-youtube>
    </div>
</div>
<app-contact></app-contact>
