import { Component, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';
import { PaisItem } from '../../interfaces/interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { Paises } from '../../paises/paises';
import { switchMap } from 'rxjs/operators';
import { GAService } from '../../services/gaservice.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-pais',
  templateUrl: './pais.component.html',
  styleUrls: ['./pais.component.scss'],
})
export class PaisComponent implements OnInit {
  private paises: Paises;
  public pais: PaisItem;
  public innerWidth: any;
  public mobile: boolean = false;

  public transporteBreakpoints = {
    640: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    801: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private gaService: GAService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.paises = new Paises();
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.innerWidth = window.innerWidth;
    }

    if (this.innerWidth <= 800) {
      this.mobile = true;
    }

    this.route.params.subscribe((params) => {
      const param = params['country'];

      if (param === 'india') {
        this.pais = this.paises.india;
      } else if (param === 'nepal') {
        this.pais = this.paises.nepal;
      } else if (param === 'tailandia') {
        this.pais = this.paises.tailandia;
      } else if (param === 'camboya') {
        this.pais = this.paises.camboya;
      } else if (param === 'vietnam') {
        this.pais = this.paises.vietnam;
      } else if (param === 'indonesia') {
        this.pais = this.paises.indonesia;
      } else if (param === 'filipinas') {
        this.pais = this.paises.filipinas;
      } else {
        this.router.navigate(['/']);
      }
      if (isPlatformBrowser(this.platformId)) {
        this.gaService.pageView(`/pais/${param}`, `Pais - ${param}`);
      }
    });

    window.scroll(0, 0);
  }
}
