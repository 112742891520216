import { Component, OnInit, Input } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-swiper-large-image',
  templateUrl: './swiper-large-image.component.html',
  styleUrls: ['./swiper-large-image.component.scss'],
})
export class SwiperLargeImageComponent implements OnInit {
  public config: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 3.1,
    centeredSlides: false,
    spaceBetween: 10,
    breakpoints: {
      640: {
        slidesPerView: 3.1,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 3.1,
        spaceBetween: 20,
      },
    },
    navigation: {
      nextEl: '.swiper-large-image-next',
      prevEl: '.swiper-large-image-prev',
    },
    pagination: true,
  };
  @Input()
  listadoItems: any[] = [];

  @Input() mobile = false;

  constructor() {}

  ngOnInit(): void {}
}
