import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
// import { AuthService } from '../auth.service';

@Injectable()
export class FormService {
  constructor(private _http: HttpClient) {}

  send(pName, pPhone, pMail) {
    const json = {
      name: pName,
      phone: pPhone,
      mail: pMail
    };

    const params =
    //   'authorization=' +
    //   this._authService.getToken() +
      'json=' +
      JSON.stringify(json);
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    return this._http.post(
      environment.url + '/exp/send',
      params,
      { headers: headers }
    );
  }
}
